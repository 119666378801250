<template>
	<TnCard
		class="card-vertical"
		:class="{ 'with-arrow': arrow }"
		v-bind="filteredAttrs"
		borderRadius="l"
		:hover="hoverSize"
		:color="blue ? 'blue' : ''"
		@click="$emit('click')"
	>
		<div
			class="top"
			v-if="$slots['graphic']"
		>
			<div
				class="graphic"
				:class="iconClasses"
			>
				<!-- @slot For graphic, such as an image, or product-icon. `icon`-prop will limit size, else will fill. -->
				<slot name="graphic"></slot>
			</div>
		</div>
		<div
			v-if="$slots['badge']"
			class="badge"
		>
			<!-- @slot For badge, placement top upper-left. -->
			<slot name="badge" />
		</div>
		<div
			class="content"
			v-if="$slots['default']"
		>
			<!-- @slot For any text content. -->
			<slot></slot>
		</div>
		<div
			class="actions"
			v-if="$slots['actions'] && !(filteredAttrs.to || filteredAttrs.href)"
		>
			<!-- @slot For actions, such as buttons. Placement: bottom of the card.
                  NB! Will be ignored if to/href is provided and card is clickable -->
			<slot name="actions" />
		</div>
		<TnIcon
			v-if="arrow && !$slots['actions']"
			size="l"
			class="arrow"
			name="cta-right"
		/>
	</TnCard>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
	name: "TnCardVertical",

	props: {
		icon: { type: Boolean, default: false },
		iconSize: { type: String, default: "l" },
		arrow: { type: Boolean, default: false },
		blue: { type: Boolean, default: false },
	},
	emits: [/** * Event emitted when the card is clicked */ "click"],

	computed: {
		hoverSize() {
			if (this.$slots["actions"]) {
				return false;
			}
			return this.$attrs.border ? "s" : "m";
		},
		iconClasses() {
			return this.icon ? [`icon-${this.iconSize}`] : [];
		},
		/* Filter attributes to prevent passing through to TnCard/NuxtLink */
		filteredAttrs() {
			const attrs = this.$attrs;
			return attrs;
		},
	},
});
</script>

<style lang="scss" scoped>
@use "@/assets/scss/variables" as variables;

.card-vertical {
	--card-content-color: #{variables.$color-neutrals-black};
	--card-graphic-color: #{variables.$color-primary-mid};
	--card-actions-color: #{variables.$color-cta-default};
	--card-actions-hover-color: #{variables.$color-cta-hover};

	display: flex;
	flex-direction: column;

	&.dark {
		--card-content-color: #{variables.$color-neutrals-25-tint};
		--card-graphic-color: #{variables.$color-primary-superlight};
		--card-actions-color: #{variables.$color-cta-dark-default};
		--card-actions-hover-color: #{variables.$color-cta-dark-hover};
	}

	&:hover {
		.arrow {
			color: var(--card-actions-hover-color);
		}
	}

	&.with-arrow {
		.content {
			padding-bottom: variables.$spacing-3xl;
		}
	}

	&:not(:has(.top)) .content {
		margin-top: variables.$spacing-m;
	}
}

.top {
	width: 100%;

	.graphic {
		width: 100%;
		overflow: hidden;
		height: 198px;

		:deep() {
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		&.icon-s,
		&.icon-m,
		&.icon-l {
			margin: variables.$spacing-2xl auto 0;

			:deep() {
				img {
					width: 100% !important;
				}
			}
		}

		&.icon {
			&-s {
				width: 120px;
				height: 120px;
			}

			&-m {
				width: 160px;
				height: 160px;
			}

			&-l {
				width: 180px;
				height: 180px;
			}
		}
	}
}

.content {
	box-sizing: border-box;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: variables.$spacing-l;
	color: var(--card-content-color);
	position: relative;
}

.arrow {
	color: var(--card-actions-color);
	float: right;
	position: absolute;
	right: variables.$spacing-l;
	bottom: variables.$spacing-l;
	transform: translateY(25%);
}

.badge {
	position: absolute;
	top: 0;
	left: variables.$spacing-l;
}

.actions {
	margin-top: auto;
	padding: 0 variables.$spacing-l variables.$spacing-l variables.$spacing-l;
}
</style>
